input[type="checkbox"].appearance-none {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: #fff;
    margin: 0;
    font: inherit;
    color: #4CAF50;
    width: 16px;
    height: 16px;
    border: 1px solid #D1D5DB;
    border-radius: 4px;
    display: inline-block;
    position: relative;
    cursor: pointer;
    transition: background-color 0.3s, border-color 0.3s;
}

input[type="checkbox"].appearance-none:checked {
    border-color: var(--primary-600);
    background-color: var(--primary-600);
}

input[type="checkbox"].appearance-none:checked::after {
    content: '';
    position: absolute;
    top: 40%;
    left: 50%;
    width: 4px;
    height: 8px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: translate(-50%, -50%) rotate(45deg);
}

input[type="checkbox"].appearance-none:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba(76, 175, 80, 0.5);
}

input[type="radio"].appearance-none {
    position: relative;
    width: 16px;
    height: 16px;
    border: 1px solid #D1D5DB;
    border-radius: 50%;
    background-color: #fff;
    cursor: pointer;
    transition: background-color 0.3s, border-color 0.3s;
}

input[type="radio"].appearance-none:checked {
    border-color: var(--primary-600);
    background-color: var(--primary-600);
}

input[type="radio"].appearance-none:checked::after {
    content: '';
    position: absolute;
    top: 4px;
    left: 4px;
    width: 6px;
    height: 6px;
    background-color: white;
    border-radius: 50%;
    transition: background-color 0.3s, transform 0.3s;
}

input[type="radio"].appearance-none:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba(76, 175, 80, 0.5);
}

input[type="radio"].appearance-none:checked::after {
    animation: checkmark-radio 0.3s forwards;
}

@keyframes checkmark-radio {
    from {
        opacity: 0;
        transform: scale(0);
    }
    to {
        opacity: 1;
        transform: scale(1);
    }
}

/*SUPPORT PAGE innerHtml from wysiwyg*/

#support-page img {
    display: inline-block;
    border-radius: 0.6rem;
    padding: 2px;
    width: 1em;
}

#support-page span {
    line-height: 2.2rem;
}

html.theme-dark {
    #support-page img {
        background-color: white;
    }
}

/*GLOBAL SCROLLBAR*/

* {
    scrollbar-width: thin;
    scrollbar-color: var(--primary-500) var(--gray-100); /* Thumb and track colors */
}


/*SCROLLBAR FOR ANIMATION PAPER BURNING*/

.burn-container *{
    scrollbar-width: thin;
    scrollbar-color: #a89b7b var(--gray-100); /* Thumb and track colors */
}

/*MOBILE CALENDAR MONTH SLIDER*/
@media (max-width: 640px) {
    .mobile-month-slider{
        width: calc(100% + 2rem);
    }
}
