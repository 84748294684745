.app-bg-backdrop-green {
    background-size: cover;
    background: url('../assets/images/bg_green_desktop.png') no-repeat center;
}

.app-bg-backdrop-blue {
    background-size: cover;
    background: url('../assets/images/bg_blue_desktop.png') no-repeat center;
}

.app-bg-backdrop-yellow {
    background-size: cover;
    background: url('../assets/images/bg_yellow_desktop.png') no-repeat center;

}

.app-bg-backdrop-dark-green {
    background-size: cover;
    background: url('../assets/images/bg_dark-green_desktop.png') no-repeat center;
}

@media (max-width: 390px) {
    .app-bg-backdrop-green {
        background-size: cover;
        background: url('../assets/images/bg_green_mobile.png') no-repeat center;
    }

    .app-bg-backdrop-blue {
        background-size: cover;
        background: url('../assets/images/bg_blue_mobile.png') no-repeat center;
    }

    .app-bg-backdrop-yellow {
        background-size: cover;
        background: url('../assets/images/bg_yellow_mobile.png') no-repeat center;
    }

    .app-bg-backdrop-dark-green {
        background-size: cover;
        background: url('../assets/images/bg_dark-green_mobile.png') no-repeat center;
    }

}
