@import "styles/tailwind-input.css";
@import "styles/reset.css";
@import "styles/own.css";
@import "styles/theme.css";
@import "styles/theme-backgrounds.css";
@import "styles/calendar-backgrounds.css";
@import "styles/animations.css";
@import "react-quill/dist/quill.snow.css";

body {
    width: 100vw;
    max-width: 100dvw;
    max-height: 100dvh;
    height: 100vh;

    #root {
        width: 100%;
        height: 100%;
    }

    @apply text-dark;

    @apply font-nunito;
}

.ql-toolbar{
    display: none;
}

.ql-editor{
    padding: 0;
}

.ql-container.ql-snow {
    border: none!important;
    padding: 0;
}

.ql-editor span {
    display: inline-block;
}
