.bg-winter-light {
    background-image: url('../assets/images/winter-light-desktop.png');
    background-size: cover;
    background-position: center;
}

.bg-winter-dark {
    background-image: url('../assets/images/winter-dark-desktop.png');
    background-size: cover;
    background-position: center;
}

.bg-spring-light {
    background-image: url('../assets/images/spring-light-desktop.png');
    background-size: cover;
    background-position: center;
}

.bg-spring-dark {
    background-image: url('../assets/images/spring-dark-desktop.png');
    background-size: cover;
    background-position: center;
}

.bg-summer-light {
    background: url('../assets/images/summer-light-desktop.png');
    background-size: cover;
    background-position: center;
}

.bg-summer-dark {
    background-image: url('../assets/images/summer-dark-desktop.png');
    background-size: cover;
    background-position: center;
}

.bg-autumn-light {
    background-image: url('../assets/images/autumn-light-desktop.png');
    background-size: cover;
    background-position: center;
}

.bg-autumn-dark {
    background-image: url('../assets/images/autumn-dark-desktop.png');
    background-size: cover;
    background-position: center;
}

@media (max-width: 390px) {
    .bg-winter-light {
        background-image: url('../assets/images/winter-light-mobile.png');
        background-size: cover;
        background-position: center;
    }

    .bg-winter-dark {
        background-image: url('../assets/images/winter-dark-mobile.png');
        background-size: cover;
        background-position: center;
    }

    .bg-spring-light {
        background-image: url('../assets/images/spring-light-mobile.png');
        background-size: cover;
        background-position: center;
    }

    .bg-spring-dark {
        background-image: url('../assets/images/spring-dark-mobile.png');
        background-size: cover;
        background-position: center;
    }

    .bg-summer-light {
        background-image: url('../assets/images/summer-light-mobile.png');
        background-size: cover;
        background-position: center;
    }

    .bg-summer-dark {
        background-image: url('../assets/images/summer-dark-mobile.png');
        background-size: cover;
        background-position: center;
    }

    .bg-autumn-light {
        background-image: url('../assets/images/autumn-light-mobile.png');
        background-size: cover;
        background-position: center;
    }

    .bg-autumn-dark {
        background-image: url('../assets/images/autumn-dark-mobile.png');
        background-size: cover;
        background-position: center;
    }
}
