@keyframes slide-up {
    from {
        bottom: -100%;
    }
    to {
        bottom: 0;
    }
}

@keyframes slide-down {
    from {
        bottom: 0;
    }
    to {
        bottom: -100%;
    }
}

.animate-slide-up {
    animation: slide-up 0.3s ease-in-out forwards;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
}

.animate-slide-down {
    animation: slide-down 0.3s ease-in-out forwards;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
}


@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

/* PAGE IMAGE LOADER*/
@keyframes pulse-loader {
    0% {
        transform: scale(1);
        opacity: 0.9;
    }
    25% {
        transform: scale(0.75);
        opacity: 0.85;
    }
    50% {
        transform: scale(0.6);
        opacity: 0.8;
    }
    75% {
        transform: scale(0.75);
        opacity: 0.85;
    }
    100% {
        transform: scale(1);
        opacity: 0.9;
    }
}

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}


.image-loader-container {
    /*position: relative;*/
    /*display: flex;*/
    /*align-items: center;*/
    /*justify-content: center;*/
    /*animation: pulse 2s infinite linear;*/
    width: 72px;
    height: 72px;
}

.pulsating-image-loader {
    animation: pulse-loader 2s infinite linear;
    z-index: 2;
    position: relative;
}

.image-loader-rotating-border {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 4px solid transparent;
    border-top-color: var(--primary-400);
    border-radius: 50%;
    animation: rotate 1s linear infinite;
    z-index: 1;
}

/*BURN PAPER*/
.burn-container {
    position: relative;
    width: 300px;
    height: 400px;
}

.paper-container {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: #f5f5dc;
    overflow: hidden;
    border: 2px solid #a89b7b;
    border-radius: 8px;
    opacity: 1;
    transition: opacity 8s ease-in-out, transform 6s, border-radius 6s;
}

.paper-content {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    padding: 10px 20px;
    font-size: 20px;
    text-align: center;
    z-index: 1;
    transition: opacity 6s ease-in-out;
}

.burn .paper-container {
    /*animation: destroyContainer 6s forwards;*/
    opacity: 0;
}

.burn .paper-content {
    opacity: 0;
}

@keyframes destroyContainer {
    0% {
        transform: none;
        border-radius: 8px;
    }
    30% {
        transform: scale(1.02) rotate(1deg);
    }
    60% {
        transform: scale(1.1) rotate(-2deg);
        clip-path: polygon(5% 5%, 95% 5%, 95% 95%, 5% 95%);
    }
    80% {
        transform: scale(1.15) rotate(3deg);
        clip-path: polygon(10% 10%, 90% 10%, 85% 90%, 15% 90%);
    }
    100% {
        transform: scale(1.2) rotate(5deg);
        clip-path: polygon(15% 20%, 85% 15%, 80% 85%, 20% 80%);
        border-radius: 50% 10% 50% 10% / 10% 50% 10% 50%;
    }
}

.fire, .smoke, .sparks, .ashes {
    position: absolute;
    width: 40px;
    height: 40px;
    background-color: transparent;
    border-radius: 50%;
    animation-duration: 2s;
    animation-timing-function: ease-out;
    animation-iteration-count: infinite;
}

.fire {
    background-color: orange;
    animation-name: fireAnimation;
    clip-path: polygon(0% 100%, 50% 0%, 100% 100%);
    mix-blend-mode: multiply;
}

@keyframes fireAnimation {
    0% {
        opacity: 1;
        transform: translateY(0);
    }
    100% {
        opacity: 0;
        transform: translateY(-100px);
    }
}

.smoke {
    background-color: rgba(128, 128, 128, 0.5);
    animation-name: smokeAnimation;
    clip-path: polygon(10% 90%, 40% 10%, 60% 60%, 90% 10%);
}

@keyframes smokeAnimation {
    0% {
        opacity: 0;
        transform: translateY(0);
    }
    100% {
        opacity: 1;
        transform: translateY(-150px);
    }
}

.sparks {
    background-color: yellow;
    animation-name: sparksAnimation;
    clip-path: polygon(0% 100%, 50% 0%, 100% 100%);
}

@keyframes sparksAnimation {
    0% {
        opacity: 1;
        transform: translateY(0);
    }
    100% {
        opacity: 0;
        transform: translateY(-80px);
    }
}

.ashes {
    background-color: rgba(105, 105, 105, 0.8);
    animation-name: ashesAnimation;
    clip-path: polygon(20% 80%, 50% 30%, 80% 80%);
}

@keyframes ashesAnimation {
    0% {
        opacity: 0;
        transform: translateY(-30px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.burn-button {
    margin-top: 20px;
    padding: 10px 20px;
    font-size: 18px;
    color: white;
    background-color: #e63946;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s;
}

.burn-button:hover {
    background-color: #d62828;
}

.fireGlow {
    position: absolute;
    width: 60px;
    height: 60px;
    background-color: rgba(255, 165, 0, 0.3);
    border-radius: 50%;
    animation-duration: 4s;
    animation-timing-function: ease-out;
    animation-iteration-count: infinite;
    filter: blur(8px);
    opacity: 0.5;
    animation-name: fireGlowAnimation;
}

@keyframes fireGlowAnimation {
    0% {
        opacity: 0.7;
        transform: translateY(0) scale(1);
    }
    100% {
        opacity: 0;
        transform: translateY(-50px) scale(1.2);
    }
}
