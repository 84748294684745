:root{
    --gray-50: #F9FAFB;
    --gray-100: #F3F4F6;
    --gray-200: #E5E7EB;
    --gray-300: #D1D5DB;
    --gray-400: #9CA3AF;
    --gray-500: #6B7280;
    --gray-600: #4B5563;
    --gray-700: #374151;
    --gray-800: #1F2937;
    --gray-900: #111827;
}

.theme-light {
    --primary: #009D1A;
    --primary-50: #F3FFED;
    --primary-100: #D7FDCA;
    --primary-200: #BEF7AA;
    --primary-300: #74E171;
    --primary-400: #4CD162;
    --primary-500: #21BA3B;
    --primary-600: #009D1A;
    --primary-700: #007F15;
    --primary-800: #006110;
    --primary-900: #004E0D;

    --text-dark: #1E1E1E;
    --text-light: #757575;
    --text-link: #009D1A;
    --text-primary: #009D1A;

    --text-primary-50: #F3FFED;
    --text-primary-100: #D7FDCA;
    --text-primary-200: #BEF7AA;
    --text-primary-300: #74E171;
    --text-primary-400: #4CD162;
    --text-primary-500: #21BA3B;
    --text-primary-600: #009D1A;
    --text-primary-700: #007F15;
    --text-primary-800: #006110;
    --text-primary-900: #004E0D;
}

.theme-dark {
    --primary: #009D1A;
    --primary-50: #F3FFED;
    --primary-100: #CEFDCA;
    --primary-200: #ACF7AA;
    --primary-300: #74E171;
    --primary-400: #4CD162;
    --primary-500: #21BA3B;
    --primary-600: #009D1A;
    --primary-700: #007F15;
    --primary-800: #006110;
    --primary-900: #004E0D;

    --text-dark: #E1E1E1;
    --text-light: #A5A5A5;
    --text-link: #009D1A;
    --text-primary: #009D1A;

    --text-primary-50: #2A2A2A;
    --text-primary-100: #3A3A3A;
    --text-primary-200: #4A4A4A;
    --text-primary-300: #5A5A5A;
    --text-primary-400: #6A6A6A;
    --text-primary-500: #7A7A7A;
    --text-primary-600: #8A8A8A;
    --text-primary-700: #9A9A9A;
    --text-primary-800: #AAAAAA;
    --text-primary-900: #BABABA;
}
